<template>
  <div class="container parkman-page">
    <div class="df jc-sb ai-c">
      <iq-title class-name="mobileHeader">Паркмен:</iq-title>
      <iq-button
        icon="IconSettings"
        icon-height="14px"
        icon-width="14px"
        class="m-l-10"
        @onClick="handleTableColumns"
      >
        Настроить таблицу
      </iq-button>
    </div>

    <div class="parkmanWarning">
      <div class="parkmanWarning__red highlight">
        Кнопку “Вернуть на парковку” следует нажимать когда:
      </div>
      <ul class="parkmanWarning__default">
        <li>
          у водителя были обнаружены несоответствия в документах, качестве зерна
        </li>
        <li>ему запрещён проезд на територию терминала</li>
        <li>
          авто было вызвано в буфер, но фактически не подъезжает в течение
          длительного времени
        </li>
      </ul>
      <div class="parkmanWarning__red highlight">
        Это необходимо, чтобы система могла вызвать в буфер следующую машину.
      </div>
      <div class="parkmanWarning__red">
        После возвращения на парковку водитель решает возникшие проблемы, а
        затем обращается в службу поддержки и сможет проехать на выгрузку.
      </div>
    </div>

    <LoaderWrapper
      :loading="getIsLoadingCallDriver"
      text="Идет звонок водителю, пожалуйста, подождите!"
    >
      <div class="parkman-page__table-wrap">
        <el-table
          :key="updateTable"
          v-loading="getIsLoading"
          class="parkman-page__table"
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column v-if="!isMobile" align="left" width="180">
            <template slot-scope="scope">
              <iq-button
                size="mini"
                color="primary"
                :disabled="scope.row.truck_status === 'detached'"
                @onClick="setDetachStatus(scope.row)"
              >
                Вернуть на парковку
              </iq-button>
            </template>
          </el-table-column>

          <el-table-column
            v-for="item in controlTableFields"
            :key="item.prop"
            header-align="center"
            align="center"
            :width="item.width"
          >
            <template slot="header">
              <TableLabel :data="item" />
            </template>
            <template slot-scope="scope">
              <ParkmanTableCell
                :row="scope.row"
                :value="scope.row[item.prop]"
                :prop-key="item.prop"
                :command="item.command"
              />
            </template>
          </el-table-column>

          <el-table-column
            class-name="parkman-page__mobile-btns"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <iq-button
                v-if="isMobile"
                size="mini"
                color="primary"
                icon-width="18px"
                icon-height="18px"
                icon="IconClose"
                :disabled="scope.row.truck_status === 'detached'"
                @onClick="setDetachStatus(scope.row)"
              />

              <iq-button
                icon="IconGoToTerminal"
                size="mini"
                icon-width="18px"
                icon-height="18px"
                :disabled="scope.row.truck_status === 'detached'"
                @onClick="toTerminal(scope.row)"
              >
                проезд к терминалу
              </iq-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </LoaderWrapper>
    <DialogChangeTableColumn />
  </div>
</template>

<script>
import {
  FETCH_TIMESLOTS,
  GET_IS_LOADING_CALL_DRIVER,
  GET_IS_LOADING_TIMESLOTS,
  GET_PARKMAN_TABLE_FIELDS,
  GET_TIMESLOTS_FROM_STATE,
  TO_STATUS_ARRIVED,
  TO_STATUS_DETACH,
} from '@/views/parkman/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
  SET_UPDATE_ACTION,
} from '@/store/actions'
import { PARKMAN_CHANGE_TABLE_COLUMN } from '@/constants/dialogs'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { parkmanTableFields } from '@/views/parkman/data/constants'
import DialogChangeTableColumn from '@/views/parkman/components/DialogChangeTableColumn.vue'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqTitle from '@/views/ui/components/typography/IqTitle'
import LoaderWrapper from '@/UI/loader/LoaderWrapper'
import ParkmanTableCell from '@/views/parkman/components/ParkmanTableCell'
import TableLabel from '@/UI/table/table-body/components/TableLabel'

export default {
  name: 'Parkman',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1200, initial-scale=1',
      },
    ],
  },
  components: {
    IqButton,
    IqTitle,
    LoaderWrapper,
    TableLabel,
    ParkmanTableCell,
    DialogChangeTableColumn,
  },
  data() {
    return {
      parkmanTableFields,
      requestInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      activeTableFields: GET_PARKMAN_TABLE_FIELDS,
      getIsLoading: GET_IS_LOADING_TIMESLOTS,
      getArrival: GET_TERMINAL_CURRENT_ID_SLUG,
      tableData: GET_TIMESLOTS_FROM_STATE,
      cultureList: GET_CULTURE_FROM_STATE,
      getIsLoadingCallDriver: GET_IS_LOADING_CALL_DRIVER,
      terminalId: GET_TERMINAL_CURRENT_ID,
    }),
    controlTableFields() {
      return this.parkmanTableFields.filter(field =>
        this.activeTableFields.includes(field.key),
      )
    },
    updateTable() {
      return this.isMobile ? 'mobile' : 'desktop'
    },
  },
  created() {
    this.setAction(this.fetchTimeslots)
    this.fetchTimeslots()
    this.requestInterval = setInterval(() => {
      this.fetchTimeslots()
    }, 300000)
  },
  beforeDestroy() {
    clearInterval(this.requestInterval)
  },
  methods: {
    ...mapActions({
      fetchTimeslots: FETCH_TIMESLOTS,
      toStatusArrived: TO_STATUS_ARRIVED,
      toStatusDetach: TO_STATUS_DETACH,
    }),
    ...mapMutations({ setAction: SET_UPDATE_ACTION }),
    toTerminal(timeslot) {
      this.toStatusArrived(timeslot.timeslot_id)
    },
    setDetachStatus(item) {
      this.$confirm(
        `Отказать в проезде авто № ${item.plate_truck} ?`,
        'Вы уверены?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'error',
        },
      )
        .then(async () => {
          await this.toStatusDetach({
            timeslot_id: item.timeslot_id,
            unload_id: this.terminalId,
            plate_truck: item.plate_truck,
          })

          this.$alert(
            `Вы отказали в проезде авто №${item.plate_truck}, пожалуйста,
            позвоните в службу технической поддержки
            <a class="parkman-page__message-link" href="tel:8002507531">800-250-75-31</a>,
            сообщите номер авто, терминал и причину отказа`,
            'Успешно!',
            {
              confirmButtonText: 'Я сообщил',
              center: true,
              dangerouslyUseHTMLString: true,
              callback() {
                this.$message({
                  type: 'success',
                  message: `Успешно`,
                })
              },
            },
          )
        })
        .catch(() => ({}))
    },
    handleTableColumns() {
      this.setDialog({
        name: PARKMAN_CHANGE_TABLE_COLUMN,
        visible: true,
        data: {},
      })
    },
  },
}
</script>

<style lang="sass">
.parkman-page
  max-width: none
  padding: 20px 16px

  .parkmanWarning
    margin-bottom: 10px
    &__red
      color: #DB6D39
      &.highlight
        margin: 8px 0
        font-weight: 700
    &__default
      margin-block-start: 0
      margin-block-end: 0

  .icon-to-terminal
    cursor: pointer
    color: #000000
  &__message-link
    font-size: 18px
    text-decoration: none
    color: $color-blue
  &__table-wrap
    background: #FFFFFF
    border: 1px solid #F2F2F2
    box-shadow: 0 4px 4px rgba(226, 226, 226, 0.25)
    border-radius: 4px
    padding: 20px 15px
  &__table
    border: 1px solid #F2F2F2
    border-radius: 4px

    .table-control-label__label
      text-align: left
      span
        font-family: Roboto, sans-serif
        font-weight: bold
        font-size: 16px
        line-height: 18px
        font-feature-settings: 'tnum' on, 'lnum' on
        color: #909399
    .el-table__row
      .cell *
        text-align: left

    .el-table__header-wrapper
      border-bottom: 1px solid #F2F2F2

    .el-table__body-wrapper
      overflow: auto !important

  &__mobile-btns
    .cell
      .button
        margin: 0 !important
      .button + .button
        margin-top: 10px !important
  .phone
    font-weight: 600
  &__orange
    color: $color-orange-primary
  .buffer_time
    font-weight: 600

  .el-table__body, .el-table__footer, .el-table__header
    min-width: 100%
</style>
